.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    background-color: var(--soft-highlight);
    margin-top: 20px;
}

.footer > p {
    font-size: 0.8rem;
    text-align: center;
    margin: 0;
}
