.page {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px 10px 20px;
}

.page.no-padding {
    padding: 0px;
}

.page-content {
    width: 100%;
    max-width: 1600px;
    background-color: var(--background);
}

.page-content.content-max-width {
    max-width: unset !important;
}

.page-content.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 1800px) {
    .page-content {
        max-width: 1400px;
    }
}

@media (max-width: 1500px) {
    .page-content {
        max-width: 1100px;
    }
}

@media (max-width: 768px) {
    .page {
        padding: 10px 10px 0px 10px;
    }
}

@media (max-width: 300px) {
    .page {
        padding: 10px 5px 0px 5px;
    }
}
