.content-cluster {
    width: 100%;
    margin-bottom: 20px;
}

.content-cluster-title {
    font-weight: 400;
    margin-bottom: 1rem;
    margin-top: 0;
    border-bottom: 1px solid var(--secondary-text);
    padding-bottom: 0.5rem;
}

.content-cluster-subtitle {
    color: var(--secondary-text);
}

@media (max-width: 768px) {
    .content-cluster-content .navigation-card {
        width: 100%;
        padding: 10px;
        height: unset;
    }

    .content-cluster-content .navigation-card p {
        line-height: unset;
        min-height: unset;
    }
}
