.server-status-display {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.server-status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    row-gap: 5px;
}

.server-status-container .server-status {
    display: flex;
    align-items: center;
    gap: 5px;
}

.server-status-container .status-icon {
    height: 1.5rem;
    width: auto;
    vertical-align: bottom;
}

@media (max-width: 768px) {
    .server-status-container {
        flex-direction: column;
        align-items: start;
        margin-left: 10px;
        row-gap: 2px;
    }
}
