.lfm-toolbar-container {
    background-color: var(--background-light);
    display: flex;
    align-items: center;
    padding: 0;
    height: 30px;
}

.lfm-toolbar-item {
    height: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    transition: background-color 0.3s;
    cursor: pointer;
    gap: 10px;
}

.lfm-toolbar-item:hover {
    background-color: var(--highlight);
}

.screenshot-button {
    margin-left: auto;
}

.filter-section {
    margin-left: 5px;
    padding-left: 10px;
    border-left: 2px solid var(--secondary-text);
}

.filter-section.multi-select {
    border-left: none;
    padding: 10px 10px;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 140px;
    width: 100%;
    box-shadow: inset 0 0 20px var(--shadow);
}

.refresh-button.disabled {
    background-color: var(--background-light);
    transition: none;
}

.refresh-button.disabled:hover {
    background-color: var(--background-light);
}

.lfm-settings-modal {
    padding: 20px;
}

@media (max-width: 768px) {
    .lfm-toolbar-container {
        height: 40px;
    }

    .lfm-toolbar-item-icon {
        width: 30px;
        height: 30px;
    }

    .settings-button {
        margin-left: auto;
    }
}
