.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 90;
}

.modal-container {
    position: fixed;
    background-color: var(--background);
    border-radius: 5px;
    box-shadow: 0 0 20px var(--shadow);
    width: 90%;
    z-index: 91;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90vh;
    overflow-y: auto;
    box-sizing: border-box;
}

.modal-container.centered-content {
    display: flex;
    justify-content: center;
}

.modal-content.centered-content {
    display: flex;
    justify-content: center;
}

.modal-close-container {
    background-color: var(--background);
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    padding: 5px;
}

@media (max-width: 768px) {
    .modal-container.full-screen-on-mobile {
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        height: 100%;
        max-width: none !important;
        box-shadow: none;
        overflow-y: auto;
        max-height: unset;
    }

    .modal-close-container {
        position: fixed;
        top: 0px;
        right: 0px;
        cursor: pointer;
    }

    .modal-close {
        width: 30px;
        height: 30px;
    }
}
